var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('div',{staticStyle:{"position":"relative","margin":"1em 0"}},[_c('b-loading',{attrs:{"is-full-page":true,"active":_vm.loading}}),(_vm.user.banner || _vm.text)?_c('article',{staticClass:"message is-warning is-small"},[_c('div',{staticClass:"message-header"},[(!_vm.text)?_c('p',[_vm._m(0),_vm._v(" User has this banner set "),_vm._m(1)]):_c('p',[_vm._v(" Add a banner for this user ")])]),_c('div',{staticClass:"message-body content"},[(!_vm.text)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.markdown(_vm.user.banner))}}):_vm._e(),(_vm.text)?_c('b-field',[_c('b-input',{ref:"editor",attrs:{"type":"textarea","size":"is-small"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1):_vm._e(),(_vm.text)?_c('p',[_vm._m(2)]):_vm._e(),_c('div',{staticClass:"buttons"},[(!_vm.text && _vm.user.banner)?_c('button',{staticClass:"button is-small",on:{"click":_vm.edit}},[_vm._m(3),_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.text)?_c('button',{staticClass:"button is-small",on:{"click":_vm.save}},[_vm._m(4),_c('span',[_vm._v("Save")])]):_vm._e(),(_vm.text || _vm.user.banner)?_c('button',{staticClass:"button is-small",on:{"click":_vm.del}},[_vm._m(5),_c('span',[_vm._v("Delete")])]):_vm._e()])],1)]):_vm._e(),_c('div',{staticClass:"buttons"},[(!_vm.user.banner && !_vm.text)?_c('button',{staticClass:"button is-small is-warning is-outlined",on:{"click":_vm.edit}},[_vm._m(6),_c('span',[_vm._v("Add user banner")])]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-arrow-down"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-arrow-down"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://daringfireball.net/projects/markdown/basics","target":"_blank"}},[_vm._v("Markdown styling guide "),_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-external-link"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-pencil"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-floppy-o"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-trash"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-plus"})])
}]

export { render, staticRenderFns }