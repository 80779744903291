<template>
  <div
    class="box content"
  >
    <h2>Edit address</h2>
    <b-field
      v-for="(row, i) in Object.keys(addressLabels)"
      :key="i"
      :label="addressLabels[row]"
    >
      <b-input
        type="text"
        :value="newAddress[row]"
        v-model="newAddress[row]"
        :maxlength="maxLength[row]"
      />
    </b-field>
    <p
      v-if="invalidChar"
      class="error has-text-danger"
    >
      {{ invalidCharMsg }}
    </p>
    <b-button
      type="submit"
      class="button"
      @click="submitAddress"
    >
      Submit
    </b-button>
  </div>
</template>

<script>

const addressFieldPattern = /^[a-zA-Z0-9\s\/().,-]*$/;
const alphabeticPattern = /^[a-zA-Z\s]+$/;

export default {
  name: 'EditUserAddress',
  props: {
    addressData: {
      type: Object,
      required: true
    },
    uid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      invalidChar: false,
      invalidCharMsg: '',
      newAddress: {
        address: this.addressData.address,
        postcode: this.addressData.postcode,
        suburb: this.addressData.suburb,
        city: this.addressData.city,
        address1: this.addressData.address1,
        address2: this.addressData.address2,
        address3: this.addressData.address3
      },
      addressLabels: {
        address: 'Address',
        postcode: 'Postcode',
        suburb: 'Suburb',
        city: 'City',
        address1: 'Unit No./House No.',
        address2: 'Complement',
        address3: 'State'
      },
      maxLength: {
        address: 35,
        postcode: 10,
        suburb: 15,
        city: 15,
        address1: 10,
        address2: 15,
        address3: 15,
      }
    }
  },
  methods: {
    validateAddressFields(fieldValue) {
      switch (fieldValue) {
      case 'address':
      case 'suburb':
      case 'address1':
      case 'address2':
        return addressFieldPattern.test(this.newAddress[fieldValue])
      case 'postcode':
        const postcode = this.newAddress[fieldValue].toString()
        return addressFieldPattern.test(postcode)
      case 'address3':
      case 'city':
        if(!this.newAddress[fieldValue]){
          return true
        }
        return alphabeticPattern.test(this.newAddress[fieldValue])
      }
    },
    async submitAddress() {
      try {
        for(const field in this.newAddress){
          if(!this.validateAddressFields(field)){
            this.invalidChar = true
            this.invalidCharMsg = `Invalid ${this.addressLabels[field]}`
            return field
          }
        }
        await this.ecApi('admin', 'setValue', {
          uid: this.uid,
          table: 'verification',
          address: this.newAddress.address,
          postcode: this.newAddress.postcode,
          suburb: this.newAddress.suburb,
          city: this.newAddress.city,
          address1: this.newAddress.address1,
          address2: this.newAddress.address2,
          address3: this.newAddress.address3
        })
        this.$buefy.toast.open('Done!')
        await this.$emit('update')
      } catch(e){
        console.error(e)
      }
    },
  }
}
</script>
