import { render, staticRenderFns } from "./AdditionalPayload.vue?vue&type=template&id=780a0be3&scoped=true&"
import script from "./AdditionalPayload.vue?vue&type=script&lang=js&"
export * from "./AdditionalPayload.vue?vue&type=script&lang=js&"
import style0 from "./AdditionalPayload.vue?vue&type=style&index=0&id=780a0be3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "780a0be3",
  null
  
)

export default component.exports