import Vue from 'vue'
import Router from 'vue-router'
import { authGuard } from '../auth'
import { Role } from '../role'

Vue.use(Router)

const router = new Router({
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: require('@/components/Home').default,
      meta: { authorize: [] }
    },
    {
      path: '/orders',
      name: 'orders',
      component: require('@/components/Orders').default,
      meta: { authorize: [] }
    },
    {
      path: '/sell',
      name: 'Sell',
      component: require('@/components/Sell').default,
      meta: { authorize: [] }
    },
    {
      path: '/payout',
      name: 'BatchPayouts',
      component: require('@/components/BatchPayouts').default,
      meta: { authorize: [Role.Admin] }
    },
    {
      path: '/bank',
      name: 'bank',
      component: require('@/components/Bank').default,
      meta: { authorize: [] }
    },
    {
      path: '/users',
      name: 'users',
      component: require('@/components/Users').default,
      meta: { authorize: [] }
    },
    {
      path: '/user',
      name: 'user',
      component: require('@/components/User').default,
      meta: { authorize: [] }
    },
    {
      path: '/otc',
      name: 'otc',
      component: require('@/components/OTC').default,
      meta: { authorize: [Role.Admin] }
    },
    {
      path: '/coins',
      name: 'Coins',
      component: require('@/components/Coins').default,
      meta: { authorize: [] }
    },
    {
      path: '/market',
      name: 'market',
      component: require('@/components/Market').default,
      meta: { authorize: [Role.Admin] }
    },
    {
      path: '/affiliates',
      name: 'affiliates',
      component: require('@/components/Affiliates').default,
      meta: { authorize: [] }
    },
    {
      path: '/order',
      name: 'order',
      component: require('@/components/Order').default,
      meta: { authorize: [] }
    },
    {
      path: '/lbc',
      name: 'LBC',
      component: require('@/components/LBC').default,
      meta: { authorize: [] }
    },
    {
      path: '/verification',
      name: 'verification',
      component: require('@/components/Verification').default,
      meta: { authorize: [Role.Compliance] }
    },
    {
      path: '/bank-verify',
      name: 'BankVerify',
      component: require('@/components/BankVerify').default,
      meta: { authorize: [Role.Compliance] }
    },
    {
      path: '/errors',
      name: 'Errors',
      component: require('@/components/Errors').default,
      meta: { authorize: [] }
    },
    {
      path: '/suspects',
      name: 'suspects',
      component: require('@/components/Suspects').default,
      meta: { authorize: [] }
    },
    {
      path: '/stats',
      name: 'stats',
      component: require('@/components/Stats').default,
      meta: { authorize: [] }
    },
    {
      path: '/suspect-orders',
      name: 'suspectorders',
      component: require('@/components/SuspectOrders').default,
      meta: { authorize: [] }
    },
    {
      path: '/tools',
      name: 'tools',
      component: require('@/components/Tools').default,
      meta: { authorize: [Role.Admin] }
    },
    {
      path: '/ec-bank',
      name: 'ecbank',
      component: require('@/components/EcBank').default,
      meta: { authorize: [Role.Admin] }
    },
    {
      path: '/compliance',
      name: 'compliance',
      component: require('@/components/Compliance').default,
      meta: { authorize: [Role.Compliance] }
    },
    {
      path: '/agents',
      name: 'agents',
      component: require('@/components/EcaAgents').default,
      meta: { authorize: [Role.Admin] }
    },
    {
      path: '/goaml',
      name: 'goaml',
      component: require('@/components/GoAML').default,
      meta: { authorize: [Role.Compliance] }
    },
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/search',
      name: 'search',
      component: require('@/components/Search').default,
      meta: { authorize: [] }
    }
  ]
})

router.beforeEach(authGuard)

export default router
