const moment = require('moment')

const W2A_GROUP_SIZE = 5
const W2A_TARGET_GROUPS = [0]
// const W2A_USER_MIN_CREATED_DATE = new Date('2023-12-20')

const PTR_GROUP_SIZE = 2
const PTR_TARGET_GROUPS = [0]

export default {
  methods: {
    under18User (birthday) {
      const age = moment().diff(birthday, 'years')
      return age <= 17 ? true : false
    },
    over70User (birthday) {
      const age = moment().diff(birthday, 'years')
      return age >= 70 ? true : false
    },
    isW2AEnabledUser (affiliateId) {
      const userTestGroup = affiliateId % W2A_GROUP_SIZE
      return W2A_TARGET_GROUPS.includes(userTestGroup)
    },
    ptrUserGroup (affiliateId) {
      const userTestGroup = affiliateId % PTR_GROUP_SIZE
      return PTR_TARGET_GROUPS.includes(userTestGroup)
    }
  }
}
