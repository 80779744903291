import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import Email from './components/parts/Email'
import local from './localisation'
import TreeView from 'vue-json-tree-view'
import VueHead from 'vue-head'
import api from '@/api'
import { Role } from './role'
import snarkdown from 'snarkdown'
import vueDebounce from 'vue-debounce'
import { getAge } from '@/store/helpers.js'
import packageJson from '../package.json'
import axios from 'axios'
import GoogleAuth from './auth/goauthWrapper.js'

const gauthOption = {
  clientId: process.env.VUE_APP_GO_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption)

Vue.use(vueDebounce)
Vue.use(VueHead)
Vue.use(TreeView)
Vue.use(Buefy, {
  defaultIconPack: 'fa'
})

store.dispatch('init')

Vue.config.productionTip = false

let CryptoJS = require('crypto-js')

Vue.prototype.$moment = require('moment-timezone')
Vue.prototype.$local = local
Vue.prototype.$roles = Role

Vue.mixin({
  methods: {
    head (title = 'ECA') {
      if (store.getters['info/unread']) {
        title = `(${store.getters['info/unread']}) ` + title
      }
      return {
        title: {
          inner: title
        }
      }
    },
    sendEmail (data) {
      return new Promise((resolve, reject) => {
        let ComponentClass = Vue.extend(Email)
        let instance = new ComponentClass({
          propsData: {
            data: data,
            resolve: resolve,
            reject: reject
          }
        })
        instance.$mount()
        document.body.appendChild(instance.$el)
      })
    },
    markdown (text) {
      if (!text) {
        text = ''
      }
      text = text.replace(/\n/g, '  \n') // Force linebreaks since Snarkdown turns paragraphs into single <br> tags
      text = snarkdown(text) // Convert to Markdown
      text = text.replace(/<a href=/, '<a target="_blank" href=') // Open links in new tab
      return text
    },
    toInt (number) {
      number = parseInt(number, 10)
      if (!number || isNaN(number)) {
        number = 0
      }
      return number
    },
    toFloat (number) {
      number = parseFloat(number)
      if (!number || isNaN(number)) {
        number = 0
      }
      return number
    },
    sendApi (location, data = {}) {
      return api(location, data)
    },
    async pdbApi (endpoint, data = {}, auth = true, method = 'POST') {
      try {
        let json = await api(this.$local.pdb + endpoint, data, auth, method)
        if (json.hasOwnProperty('success') && json.success === true && json.hasOwnProperty('data')) {
          return json.data
        } else {
          console.log(json)
        }
      } catch (e) {
        // nothing
        console.log(e)
      }
      throw new Error('Unable to fetch PDB API')
    },
    getAge (date) {
      return getAge(date)
    },
    // drip (action, data = {}) {
    //   if (!this.$auth.user) return
    //   data.action = action
    //   data.uid = this.$auth.user.sub
    //   data.user = this.$auth.user
    //   data.accessToken = store.state.goauth.token
    //   this.sendApi(local.api + '/apiv2/drip.php', data)
    //     .catch(e => { console.log(e) })
    // },
    ecApi (api, action, data = {}) {
      return new Promise((resolve, reject) => {
        // data.nonce = new Date().getTime()
        // data.hash = CryptoJS.SHA256(data.nonce + this.$root.token).toString()
        let url = (api.substring(0, 4) === 'http') ? api : local.api + '/api/' + api + '.php'
        data.action = action
        // Add the name of the agent making the change
        if (!data.hasOwnProperty('who')) {
          data.who = store.getters['goauth/logName']
        }
        if (!store.state.goauth.token) {
          console.log('No token!!')
          reject(new Error('No token'))
        }
        data.accessToken = store.state.goauth.token
        this.sendApi(url, data)
          .then((json) => {
            if (json.hasOwnProperty('success') && json.success === true && json.hasOwnProperty('data')) {
              resolve(json.data)
            } else {
              reject(json.message)
            }
          })
          .catch(e => reject(e))
      })
    },
    apiv2 (api, action, data = {}) {
      return new Promise((resolve, reject) => {
        // data.nonce = new Date().getTime()
        // data.hash = CryptoJS.SHA256(data.nonce + this.$root.token).toString()
        let url = local.api + '/apiv2/' + api + '.php'
        data.action = action
        // data.user = this.$auth.user
        data.accessToken = store.state.goauth.token
        // Add the name of the agent making the change
        if (!data.hasOwnProperty('who')) {
          data.who = store.getters['goauth/logName']
        }
        if (!store.state.goauth.token) {
          console.log('No token!!')
          reject(new Error('No token'))
        }
        this.sendApi(url, data)
          .then((json) => {
            if (json.hasOwnProperty('success') && json.success === true && json.hasOwnProperty('data')) {
              resolve(json.data)
            } else {
              console.log(json)
              reject(json.message)
            }
          })
          .catch(e => reject(e))
      })
    },
    apName (id) {
      return 'EC' + ('0000000' + id.toString()).substr(-7)
    },
    currency (number, digits = 0) {
      number = this.toFloat(number)
      let currency = new global.Intl.NumberFormat(this.$local.locale, {
        style: 'currency',
        currency: this.$local.currency,
        useGrouping: true,
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      })
      return currency.format(number)
    },
    price (number, decimals = 2) {
      if (isNaN(number)) {
        return ''
      }

      let currency = new Intl.NumberFormat('en-NZ', {
        style: 'decimal',
        useGrouping: true,
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      })
      return '$' + currency.format(number)
    },
    nameCase (value) {
      let words = value.split(' ')
      let result = []
      for (let word of words) {
        if (word === word.toUpperCase() || word === word.toLowerCase()) {
          word = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        }
        result.push(word)
      }
      return result.join(' ')
    },
    loadOrder (orderID) {
      let users = this.$root.userData
      orderID = orderID.toUpperCase()
      console.log('Searching for ' + orderID)
      for (let user in users) {
        if (users.hasOwnProperty(user) &&
          users[user].hasOwnProperty('orders') &&
          users[user].orders.hasOwnProperty(orderID)
        ) {
          let email
          let fullName
          let verificationSent
          let location

          // Compensate for old email/verification location
          if (users[user].meta) {
            location = users[user].meta
          } else {
            location = users[user]
          }
          email = location.email
          fullName = location.fullName
          verificationSent = location.verificationSent

          let order = {
            id: orderID,
            uid: user,
            fullName: fullName,
            email: email,
            verificationSent: verificationSent || false,
            orderData: users[user].orders[orderID]
          }
          console.log(order)
          return order
        }
      }
    },
    formatBank (bsb, account, suffix, bankAccount) {
      if (bsb === null || account === null || suffix === null) {
        return bankAccount
      }
      let one = bsb.toString().substr(0, bsb.toString().length - 4).padStart(2, '0')
      let two = bsb.toString().substr(-4).padStart(4, '0')
      let thr = account.toString().padStart(7, '0')
      let fou = suffix.toString().padStart(2, '0')
      return one + '-' + two + '-' + thr + '-' + fou
    },
    ucFirst (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    },
    cryptoNum (amount, precision = 8) {
      return Math.round(amount * Math.pow(10, precision)) / Math.pow(10, precision)
    },
    getFlag (flag) {
      switch (flag) {
      case 'banned':
      case 'fraud':
        return {
          colour: null,
          colourClass: 'is-danger',
          icon: 'ban',
          iconClass: 'fa-ban',
          label: 'Banned'
        }
      case 'suspicious':
      case 'frozen':
        return {
          colour: null,
          colourClass: 'is-danger',
          icon: 'stop',
          iconClass: 'fa-stop',
          label: 'Frozen'
        }
      }
    },
    niceNumber (amount, digits = null) {
      let style = { style: 'decimal' }
      if (digits !== null) {
        digits = this.toInt(digits)
      } else if (!amount || isNaN(amount) || amount === 0 || amount < 0) {
        // No number
        return null
      } else if (amount < 1) {
        let zeros
        if (amount < 1 / Math.pow(10, 5)) {
          // Truncate at 8 decimal places
          zeros = 8
        } else {
          // Otherwise floor to 4 significant figures
          zeros = -1 - Math.floor(Math.log10(amount % 1))
          zeros += 5
        }
        amount = Math.floor(amount * Math.pow(10, zeros))
        if (amount) {
          let length = zeros - amount.toString().length
          amount = '0.' + Array(length + 1).join('0') + amount
        } else {
          amount = 0
        }
        return amount
      } else if (amount < 10) {
        // Less than 10 is 4 decimal places
        amount = Math.floor(amount * 1000) / 1000 // round down
        digits = 4
      } else if (amount < 100) {
        // Less than 100 is 3 decimal places
        amount = Math.floor(amount * 1000) / 1000 // round down
        digits = 3
      } else {
        // Otherwise 2 decimal places with grouping
        amount = Math.floor(amount * 100) / 100 // round down
        digits = 2
      }
      return new global.Intl.NumberFormat(this.$local.locale, Object.assign({
        useGrouping: true,
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
      }, style)).format(amount)
    },
    findRates (symbol, nzd, percent = 0.01, initial = 0.0) {
      let coins = store.state.coins.coins
      let niceNumber = this.niceNumber
      let currency = this.currency

      // Check the final rate less this percentage and see if it still works.
      // This doesn't affect their dollar amount, just a safety measure
      let percentPadding = 0
      let rates = []

      nzd = parseInt(nzd, 10) * (1 - initial) // Subtract the NZD surcharge
      if (isNaN(nzd) || nzd === 0) {
        return []
      } // no NZD specified

      // Use pre-calculated paths for speed
      let paths = []
      if (nzd < 200) {
        paths = coins[symbol].paths120
      } else if (nzd < 500) {
        paths = coins[symbol].paths300
      } else if (nzd < 1000) {
        paths = coins[symbol].paths750
      } else if (nzd < 3000) {
        paths = coins[symbol].paths1500
      } else {
        paths = coins[symbol].paths5000
      }

      for (let i = 0; i < paths.length; i++) {
        // For each pathway
        let pathGood = true
        let amount = nzd
        let steps = paths[i].steps
        let j = 0

        let from = 'NZD'
        let pathText = '<p>Begin with ' + currency(nzd) + ' ' + from + '</p>'

        while (j < steps.length && pathGood) {
          let step = steps[j]

          if (step.type === 'transfer') {
            // Transfer between exchanges

            pathText += '<p>Transfer ' + step.toSymbol + ' to ' + step.exchangeName + '<br>'

            // Check if there is a deposit fee
            if (step.depositFee) {
              pathText += 'Minus an exchange deposit fee of ' + step.depositFee + ' ' + step.toSymbol + '<br>'
              amount -= step.depositFee
              pathText += 'Equals ' + niceNumber(amount) + ' ' + step.toSymbol + '</p>'
              if (amount < 0) {
                pathGood = false
              }
            }
          } else {
            // Standard transaction

            amount -= amount * percent // subtract our surchage
            amount /= step.rate
            pathText += '<p>Exchange to ' + niceNumber(amount) + ' ' + step.toSymbol + ' at ' + step.exchangeName + '<br>'

            // Subtract exchange fee
            if (step.exchangeFee) {
              amount -= amount * step.exchangeFee
              pathText += 'Minus a currency exchange fee of ' + niceNumber(amount * step.exchangeFee) + ' ' + step.toSymbol + '<br>'
            }
            // Withdraw fee
            if (((steps[j + 1] && steps[j + 1].type === 'transfer') || // if it's an exchange before transfer
              j === steps.length - 1) && // or if it's the last step in the chain
              step.withdrawFee) { // and there's a withdraw fee
              amount -= step.withdrawFee
              pathText += 'Minus a withdrawal fee of ' + step.withdrawFee + ' ' + step.toSymbol + '<br>'
            }
            // Can we withdraw it?
            if (step.minWithdraw && amount * (1 - percentPadding) <= step.minWithdraw) {
              pathGood = false
            }
            // Check if less than minimum transaction size
            if (step.minExchange && amount * (1 - percentPadding) <= step.minExchange) {
              pathGood = false
            }
            // Check if negative value
            if (amount < 0) {
              pathGood = false
            }
            // Store the path data
            pathText += 'Equals ' + niceNumber(amount) + ' ' + step.toSymbol + ' at ' + step.exchangeName + '</p>'
          }
          j++
        }
        // If the path still works, store it
        if (pathGood) {
          pathText += '<p class="has-text-primary">Giving a final result of ' + niceNumber(amount) + ' ' + symbol + ', with an effective rate of $' + niceNumber(nzd / amount) + ' per ' + symbol + '</p>'
          rates.push({
            fromSymbol: 'NZD',
            toSymbol: symbol,
            amount: amount,
            path: pathText,
            steps: steps
          })
        }
      }
      // Sort the returned array by best value
      if (rates.length) {
        rates.sort(function (a, b) {
          return a['amount'] < b['amount']
        })
      }

      return rates
    },
    encrypt (data, password) {
      let version = '001'
      let iterations = 5000
      let iterationsString = ('00000000' + iterations).slice(-8) // pad iterations with zeros
      let salt = CryptoJS.lib.WordArray.random(128 / 8)
      let iv = CryptoJS.lib.WordArray.random(128 / 8)
      let key = CryptoJS.PBKDF2(password, salt, {
        keySize: 256 / 32,
        iterations: iterations
      })

      let encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
      })

      return version + salt.toString() + iv.toString() + iterationsString + encrypted.toString()
    },
    createPortfolio (data, passPhrase) {
      /*
      Version history:

      2: added purchased amount and per-coin gain
      */
      return new Promise((resolve, reject) => {
        let ciphertext = this.encrypt(JSON.stringify(data), passPhrase)
        let filename = 'Easy Crypto ' + data.id + ' - ' + this.$moment().format('YYYY-MM-DD') + '.ecp'
        /* fs.writeFile('C:/Users/Alan/Documents/Easy Crypto backup/' + filename, ciphertext, (err) => {
          if (err) {
            throw err
          } else {
            console.log('Portfolio written')
            this.activeOrders[orderID].portfolioWritten = true
          }
        }) */
        let textFileAsBlob = new Blob([ciphertext], { type: 'text/plain' })
        let downloadLink = document.createElement('a')
        downloadLink.download = filename
        downloadLink.innerHTML = 'Download File'
        if (window.webkitURL != null) {
          // Chrome allows the link to be clicked
          // without actually adding it to the DOM.
          downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob)
        } else {
          // Firefox requires the link to be added to the DOM
          // before it can be clicked.
          downloadLink.href = window.URL.createObjectURL(textFileAsBlob)
          downloadLink.style.display = 'none'
          document.body.appendChild(downloadLink)
        }
        downloadLink.click()
        resolve()
      })
    },
    hasAccess (requires = []) {
      if (!requires) {
        return true
      } else if (!Array.isArray(requires)) {
        requires = [requires]
      }
      let roles = store.state.goauth.roles
      if (typeof (roles) !== 'string') {
        return false
      }
      roles = roles.split(',')
      if (!roles) {
        // Can't get user roles, no access allowed
        return false
      } else if (roles.includes(Role.Admin)) {
        // Always allow if user has Admin role
        return true
      } else {
        // Check role matched with authorize
        return roles.some(r => requires.includes(r))
      }
    },
    routeAccess (routename) {
      if (!this.$router.hasOwnProperty('options')) {
        return false
      }
      let info = this.$router.options.routes
      // Find authorize meta for this route
      const index = info.findIndex(x => x.name === routename)
      const authorize = info[index].meta.authorize
      // Skip open-for-all route
      if (authorize.length === 0) {
        return true
      }
      // Else use route roles
      return this.hasAccess(authorize)
    },
    async verifyApi (endpoint, method, data = {}) {
      const url = local.vms.url + '/' + endpoint
      return axios({ method, url, data, headers: { 'Content-type': 'application/json', Authorization: 'Bearer ' + store.state.goauth.token } })
    }
  }
})

/* eslint-disable no-new */
new Vue({
  components: { App },
  router,
  store,
  data: {
    version: packageJson.version,
    user: null,
    dataLoaded: false,
    coins: null,
    coinsLoaded: false,
    surcharge: 0.01,
    currentRoute: null,
    loggingIn: false,
    mobile: false,
    accessToken: null
  },
  computed: {
    info () {
      return this.$store.state.info.info
    }
  },
  mounted () {
    if (store.state.goauth.token !== null) {
      setInterval(() => {
        // Regularly update all stores
        this.updateAllStore()
        // Renew token
        this.refreshToken()
      }, 5 * 60 * 1000)
    }
    // Watch resive events
    this.mobile = (window.innerWidth <= 768)
    window.addEventListener('resize', () => {
      this.mobile = (window.innerWidth <= 768)
    })
  },
  created () {
    if (store.state.goauth.refresh !== null) {
      this.refreshToken()
    }
    // Disabling FRC temporarily for migration to GCP
    // this.fetchFRC()
  },
  methods: {
    /**
     * Used to sign in into the system
     * @returns {*}
     */
    async signIn () {
      await this.$gAuth
        .signIn()
        .then(GoogleUser => {
          const idToken = GoogleUser.getAuthResponse().id_token
          this.login(idToken)
            .then(() => {
              if (store.state.goauth.roles === null) {
                this.signOut()
              } else {
                // Successfully logged in
                store.dispatch('updateAll')
                store.dispatch('getBanReasons')
                let roles = store.state.goauth.roles
                let allRoles = Role
                let arrRoles = []
                for (let i in allRoles) {
                  arrRoles.push(allRoles[i])
                }
                if (typeof (roles) === 'string') {
                  roles = roles.split(',')
                  console.log('roles:' + roles)
                  if (roles.some(res => arrRoles.includes(res))) {
                    console.log('ok')
                  } else {
                    this.signOut()
                  }
                } else {
                  this.signOut()
                }
              }
            })
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    async login (accessToken) {
      await api(local.authServer, {
        action: 'login',
        token: accessToken
      }, false).then((res) => {
        if (res.success && res.hasOwnProperty('data') && res.data) {
          let authdata = {
            email: res.data.email,
            name: res.data.name,
            photo: res.data.photo,
            roles: res.data.roles,
            token: res.data.accessToken,
            refresh: res.data.refreshToken
          }
          store.dispatch('goauth/update', authdata)
        }
      })
    },
    async refreshToken () {
      console.log('refreshing token...')
      await api(local.authServer, {
        action: 'refreshToken',
        refresh: store.state.goauth.refresh
      }, false).then((res) => {
        if (res.success && res.hasOwnProperty('data') && res.data) {
          let authdata = {
            email: res.data.email,
            name: res.data.name,
            photo: res.data.photo,
            roles: res.data.roles,
            token: res.data.accessToken,
            refresh: res.data.refreshToken
          }
          store.dispatch('goauth/update', authdata)
        }
      }).catch(error => {
        // Access token expired before renewing. Need to sign out first for safety
        console.log('error', error)
        this.signOut()
      })
    },
    /**
     * Used to sign out from the system
     * @returns {*}
     */
    async signOut () {
      let res = await this.$gAuth.signOut()
      if (res) {
        await this.$store.dispatch('resetStore')
        if (this.$route.fullPath !== '/') {
          await this.$router.push({ path: '/' })
        }
      }
    },
    updateAllStore () {
      store.dispatch('updateAll').then(r => null)
      console.log('Store updated')
    },
  },
  template: '<App/>'
}).$mount('#app')
