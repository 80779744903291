<template>
  <div class="container is-desktop mb-3">
    <div class="columns">
      <div class="column">
        <label class="label">Date Range</label>
        <b-datepicker
          placeholder="Click to select..."
          v-model="dates"
          range
        />
      </div>
    </div>
    <div>
      <button
        class="button is-primary"
        @click="generateReportAction"
        :disabled="isLoading"
        v-text="isLoading ? 'Generating...' : 'Generate Report'"
      />
      <div v-if="xml">
        <br>
        <pre>{{ xml }}</pre>
      </div>
      <br>
      <br>
      <br>
    </div>
  </div>
</template>

<script>

import api from '@/api';
import dayjs from 'dayjs';

export default {
  name: 'PTR',

  data() {
    return {
      /**
       * @type {Array<Date>} dates
       */
      dates: [],
      payload: {
        indicators: [],
        startDate: null,
        endDate: null
      },
      xml: null,
      isLoading: false
    }
  },
  mounted() {

  },
  methods: {
    getToken() {
      return this.$store.state.goauth.token
    },
    formatXml(xml, tab) { // tab = optional indent value, default is tab (\t)
      let formatted = '', indent = '';
      tab = tab || '\t';
      xml.split(/>\s*</).forEach(function (node) {
        if (node.match(/^\/\w/)) {
          indent = indent.substring(tab.length);
        } // decrease indent by one 'tab'
        formatted += indent + '<' + node + '>\r\n';
        if (node.match(/^<?\w[^>]*[^/]$/)) {
          indent += tab;
        } // increase indent
      });
      return formatted.substring(1, formatted.length - 3);
    },
    generateReportAction() {
      this.isLoading = true;
      this.generateReport().finally(() => {
        this.isLoading = false
      });
    },
    async generateReport() {
      if (this.dates.length === 0) {
        alert('Please select a date range')
        return
      }

      this.payload.startDate = dayjs(this.dates[0]).format('YYYY-MM-DD')
      this.payload.endDate = dayjs(this.dates[1]).format('YYYY-MM-DD')
      this.payload.indicators = ['DIA'] // Indicators are hard-coded for now for PTR

      const ptr = await fetch(this.$local.apiBaseUrl + '/api/v2/eca-ptr', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.getToken()}`
        },
        method: 'POST',
        body: JSON.stringify(this.payload)
      }).catch(err => {
        alert(err.message)
      })

      this.xml = this.formatXml(await ptr.text())
    }
  }
}

</script>
