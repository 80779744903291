<template>
  <div id="app">
    <template v-if="$store.state.goauth.token">
      <Navbar />
      <div class="container">
        <router-view />
      </div>
    </template>
    <template v-if="!$store.state.goauth.token">
      <section class="hero is-fullheight">
        <div class="hero-body">
          <div
            class="container has-text-centered"
            style="max-width:400px !important;"
          >
            <div v-if="$store.state.goauth.email && !$store.state.goauth.token">
              Session found, if this page not redirecting please refresh.
            </div>
            <div v-else>
              <div class="field">
                <b-button
                  type="is-primary"
                  outlined
                  @click="loginWithGoogle"
                >
                  Sign In
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import Navbar from './components/Navbar'

export default {
  name: 'EasyCryptoAdmin',
  components: { Navbar },
  data () {
    return {
      email: null,
      error: null,
      loggingIn: false,
      hasAuth: false
    }
  },
  head () {
    return this.head()
  },
  computed: {
    auth () {
      return this.$store.state.goauth
    },
    unread () {
      return this.$store.getters['info/unread']
    }
  },
  watch: {
    unread () {
      this.$emit('updateHead')
    }
  },
  methods: {
    /**
     * Used to sign in into the system
     * @returns {*}
     */
    loginWithGoogle () {
      this.$root.signIn()
    }
  }
}
</script>

<style>
/* CSS */
.break-all {
  word-break: break-all;
}

pre.clean {
  font-family: Lato, sans-serif;
  padding: 0;
  margin: 0;
  background: transparent;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
